import { AttachmentT } from '@/Assistant/Dialogue/kind';
import { MentionData } from '@draft-js-plugins/mention';
import * as R from 'ramda';
import {
  Feedback,
  MarkdownMdObject,
  MarkdownTextObject,
  ReceiveEvents,
  SessionEvents,
  UserConnectEvent,
} from './LiveAgentTypes';
export type User = {
  id: string;
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  deleted: boolean;
};

export type Agents = {
  id: number;
  first_name: string;
  last_name: string;
  user_name: string;
  email: string;
  session_status: string;
};
export type BotsLA = {
  id: string;
  name: string;
};
export type Team = {
  id: number;
  agents: Agents[];
};

export type Sessions = {
  meta: any;
  id: string;
  user: User;
  platform: string;
  conversation_id?: string;
  feedback: any;
  escalated_by: string;
  callback_url: string;
  assigned_team: {
    id: string;
    name: string;
    workspace_id: string;
    deleted: number;
  };
  assigned_agent: AgentT | null;
  session_status: string;
  tags: Tag[];
  workspace_name?: string;
  attribute_title: any;
  chat_id: number;
  deleted: boolean;
  start_time: string;
  end_time: any;
  custom_message: any;
  mentions: [];
  custom_attributes: any;
  chat_source: {
    id: string;
    name: string;
  };
};

export interface AgentT {
  teams: BotsLA[];
  id: string;
  auth_user_id: number;
  first_name: string;
  last_name: string;
  user_name: string;
  display_name: string;
  email: string;
  agent_status: string;
  role_id: number;
  team_id: any;
  deleted: boolean;
}
export interface Tag {
  id: number;
  tag: string;
}
export interface CustomMessage {
  markdown_text: string;
  normal_text: string;
}
export const initialSessionData = {
  id: '',
  user: {
    id: 0,
    first_name: '',
    last_name: '',
    user_name: '',
    email: '',
  },
  platform: '',
  escalated_by: '',
  feedback: '',
  start_time: '',
  end_time: '',
  session_status: '',
  chat_id: 0,
  tags: [],
  workspace_name: '',
  title: '',
  deleted: false,
  custom_message: '',
  mentions: [],
  custom_attributes: '',
};
export type SessionResT = {
  sessions: SessionResponse;
  chats_in_queue: number;
  my_chats: number;
  mentions: number;
  open: number;
  closed: number;
};

export type SessionResponse = {
  result: Sessions[];
  number_of_pages: number;
  current_page: number;
  total_count: number;
};
export enum ActionT {
  VIEW,
  SELECT,
  MOVETRASH,
  RESTORE,
  DELETE,
  CLOSEDTRASH,
  MULTITRASH,
}
export interface View_T {
  action: ActionT.VIEW;
}
export const VIEW: View_T = {
  action: ActionT.VIEW,
};
export interface Select_T {
  action: ActionT.SELECT;
  data: string[];
}
export interface MoveTrash_T {
  action: ActionT.MOVETRASH;
  data: string[];
}
export interface CloseTrash_T {
  action: ActionT.CLOSEDTRASH;
  data: string[];
}
export interface MultiTrash_T {
  action: ActionT.MULTITRASH;
  data: string[];
}
export interface Restore_T {
  action: ActionT.RESTORE;
  data: string;
}
export interface Delete_T {
  action: ActionT.DELETE;
  data: string;
}
export type StatusM = {
  status: {
    session_status?: string;
    session_ids?: string[];
    status?: string;
  };
  workspace: string;
  sessionID?: string;
  session_status?: string;
};

export type AddTagT = {
  tagInfo: {
    name?: string | number;
    title?: string | null;
    agent_id?: string;
    team_id?: string;
    status?: string;
  };
  workspace: string;
  sessionID: string;
};

export type AttrT = {
  workspace: string;
  sessionID: string;
  attributes: {
    [x: string]: string;
  }[];
};

export type AgentStatusT = {
  status: {
    agent_status: string;
  };
  workspace: string;
  agentID: string | number;
};
export interface ROUTER_PROPS {
  match: {
    params: {
      workspacename: string;
      ID: string;
      agentId: string;
      status: string;
    };
  };
}

export type currentTab =
  | 'All'
  | 'Queue'
  | 'My'
  | 'Open'
  | 'Closed'
  | 'Trash'
  | 'Mention';

export type tab = 'Bot' | 'DashBoard' | 'Chat History' | 'Settings';

export type tabStatus =
  | 'allSession'
  | 'queueStatus'
  | 'myStatus'
  | 'openStatus'
  | 'closedStatus'
  | 'trashStatus'
  | 'mentionStatus';

export type PageStateLive =
  | View_T
  | Select_T
  | MoveTrash_T
  | Restore_T
  | Delete_T
  | CloseTrash_T
  | MultiTrash_T;

export type Customdate = {
  from_date: null | string;
  to_date: null | string;
};
export const AllSessionMock: SessionResT = {
  sessions: {
    result: [
      {
        id: '771bef0f-9eb6-4300-b68c-f9a1974e7a4a',
        platform: 'tryme',
        user_id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
        conversation_id: 'tryme_a5b42b62-4627-4871-a811-77732b214ca6',
        feedback: null,
        meta: {
          user_id: 'dhanasekaran.t@workativ.com',
          conversation_id: 'tryme_a5b42b62-4627-4871-a811-77732b214ca6',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/testing_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/tryme_a5b42b62-4627-4871-a811-77732b214ca6',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/testing_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/tryme_a5b42b62-4627-4871-a811-77732b214ca6',
          location: '',
          timezone: '',
          custom_message: '"Hi agent"',
        },
        callback_url:
          'http://channel-tryme_tryme:9191/v2/agent/testing_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/tryme/message/tryme_a5b42b62-4627-4871-a811-77732b214ca6',
        session_status: 'Queued',
        attribute_title: null,
        chat_id: 31,
        deleted: 0,
        start_time: '2024-07-18T15:07:41',
        end_time: '2024-07-18T15:08:02',
        custom_attributes: null,
        custom_message: 'Hi agent',
        user: {
          id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
          first_name: 'Dhanasekaran T',
          last_name: 'Dhanasekaran T',
          email: 'dhanasekaran.t@workativ.com',
          deleted: 0,
        },
        assigned_team: {
          id: '24241234',
          name: 'asjhdkjfasj',
        },
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
      {
        id: 'f6b87e43-744e-4ebb-9771-26ba82eb615b',
        platform: 'teams',
        user_id: 'e7537abd-1976-4327-81a3-49b7bd7c9cc7',
        conversation_id:
          'a:1O0ukDDh8RNmKrzrhsEJwENmdi11aATmtXOG8lB2euNegEtJCcU2t2CEMaXQOmFGNDoc6TB5SAwVdvDM1Y08zWYvry00qAEKPA8PHXPZLJK01xj1jCNbjgH_95ZNUB9sG',
        feedback: null,
        meta: {
          user_id:
            '29:19aVcT4gOwXpuGr4kW9iCIlrBh7lcpGH0gxE7EAAqxb2wLmkXVOLZw2fJMQjzNf_mf5sBaplolPJg-kKzLyJ-eQ',
          conversation_id:
            'a:1O0ukDDh8RNmKrzrhsEJwENmdi11aATmtXOG8lB2euNegEtJCcU2t2CEMaXQOmFGNDoc6TB5SAwVdvDM1Y08zWYvry00qAEKPA8PHXPZLJK01xj1jCNbjgH_95ZNUB9sG',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/166ca5d7-6235-4ab3-a0d5-2a6eafaeae31/b43d3119-62aa-46ee-955d-39ffb191a690_166ca5d7-6235-4ab3-a0d5-2a6eafaeae31_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/166ca5d7-6235-4ab3-a0d5-2a6eafaeae31/b43d3119-62aa-46ee-955d-39ffb191a690_166ca5d7-6235-4ab3-a0d5-2a6eafaeae31_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Hi Agent"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/e63937fd-7d2f-45a1-9a27-ced2c8306f3d/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Queued',
        attribute_title: null,
        chat_id: 30,
        deleted: 0,
        start_time: '2024-07-18T14:40:45',
        end_time: '2024-07-18T14:40:45',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Hi Agent',
        },
        user: {
          id: 'e7537abd-1976-4327-81a3-49b7bd7c9cc7',
          first_name: 'Shri Ghayathri Ramesh',
          last_name: 'Shri Ghayathri Ramesh',
          email: 'shri.Ghayathri@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: '166ca5d7-6235-4ab3-a0d5-2a6eafaeae31',
          name: 'TestUI',
        },
      },
      {
        id: '88cd4d1f-77b8-47c5-a51e-4158dcd4bd5c',
        platform: 'teams',
        user_id: 'e7537abd-1976-4327-81a3-49b7bd7c9cc7',
        conversation_id:
          'a:1O0ukDDh8RNmKrzrhsEJwENmdi11aATmtXOG8lB2euNegEtJCcU2t2CEMaXQOmFGNDoc6TB5SAwVdvDM1Y08zWYvry00qAEKPA8PHXPZLJK01xj1jCNbjgH_95ZNUB9sG',
        feedback: null,
        meta: {
          user_id:
            '29:19aVcT4gOwXpuGr4kW9iCIlrBh7lcpGH0gxE7EAAqxb2wLmkXVOLZw2fJMQjzNf_mf5sBaplolPJg-kKzLyJ-eQ',
          conversation_id:
            'a:1O0ukDDh8RNmKrzrhsEJwENmdi11aATmtXOG8lB2euNegEtJCcU2t2CEMaXQOmFGNDoc6TB5SAwVdvDM1Y08zWYvry00qAEKPA8PHXPZLJK01xj1jCNbjgH_95ZNUB9sG',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/166ca5d7-6235-4ab3-a0d5-2a6eafaeae31/da75028f-f4a7-4e11-a587-ffa2bc947da0_166ca5d7-6235-4ab3-a0d5-2a6eafaeae31_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/166ca5d7-6235-4ab3-a0d5-2a6eafaeae31/da75028f-f4a7-4e11-a587-ffa2bc947da0_166ca5d7-6235-4ab3-a0d5-2a6eafaeae31_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Hi Agent"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/e63937fd-7d2f-45a1-9a27-ced2c8306f3d/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Closed',
        attribute_title: null,
        chat_id: 29,
        deleted: 0,
        start_time: '2024-07-18T12:19:44',
        end_time: '2024-07-18T12:42:54',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Hi Agent',
        },
        user: {
          id: 'e7537abd-1976-4327-81a3-49b7bd7c9cc7',
          first_name: 'Shri Ghayathri Ramesh',
          last_name: 'Shri Ghayathri Ramesh',
          email: 'shri.Ghayathri@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: '166ca5d7-6235-4ab3-a0d5-2a6eafaeae31',
          name: 'TestUI',
        },
      },
      {
        id: 'b08b36fd-fb0d-43bf-a365-fcbc0bad0956',
        platform: 'teams',
        user_id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
        conversation_id:
          'a:1yOKstPCG33_Yj25jAIha2EJP0udmt7BKFBHYVHpn1yXXHNfnB0gNbyhvVJWI2BIt_RJrEWGzNguCwasytlq09ta9_FYAlHFMZUIU3sgifdr_Hwzi0nxDu5DZXdVh8JeX',
        feedback: null,
        meta: {
          user_id:
            '29:1hDt_1v1nVRDZARgmznDukiSbdaOgCeSmH1UbUhfHOix_ObqvqpyL4C-qq03A2hB31I-HhidV_ZI6c47Y9jNi3g',
          conversation_id:
            'a:1yOKstPCG33_Yj25jAIha2EJP0udmt7BKFBHYVHpn1yXXHNfnB0gNbyhvVJWI2BIt_RJrEWGzNguCwasytlq09ta9_FYAlHFMZUIU3sgifdr_Hwzi0nxDu5DZXdVh8JeX',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/94b0ab89-e0f0-4b08-8537-d8d73ab51363_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/94b0ab89-e0f0-4b08-8537-d8d73ab51363_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Live agent connected"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/c549316a-0441-4bdb-af1d-7d0748604820/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'InProgress',
        attribute_title: null,
        chat_id: 28,
        deleted: 0,
        start_time: '2024-07-18T11:36:34',
        end_time: '2024-07-18T11:45:25',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Live agent connected',
        },
        user: {
          id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
          first_name: 'Dhanasekaran T',
          last_name: 'Dhanasekaran T',
          email: 'dhanasekaran.t@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
      {
        id: '66fa2288-15e1-45a1-a615-c53e44099951',
        platform: 'teams',
        user_id: '1920a069-00c0-4d94-a1bd-0e665e8d844c',
        conversation_id:
          'a:1kSA8avJD1mMdX-xAwY08u2-yjrF4xq92JOwcdLUhOqYCXpBtXPf6wlxm6SNg67q-fQ2SSHkEVGPZQoCfOZstgwrDPw8Qr4_8mleZCaJ8lfamf0ADyMq5nb7nOXiO4Ll_',
        feedback: null,
        meta: {
          user_id:
            '29:1iUYxt3shwY6cbK1EPhmgkIRhenYIpOMy4NYZnuO4GaBqlQVT3-oIYr4IMu_mZnGL9YTY1BIoRJyIqnof4ts1pg',
          conversation_id:
            'a:1kSA8avJD1mMdX-xAwY08u2-yjrF4xq92JOwcdLUhOqYCXpBtXPf6wlxm6SNg67q-fQ2SSHkEVGPZQoCfOZstgwrDPw8Qr4_8mleZCaJ8lfamf0ADyMq5nb7nOXiO4Ll_',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/9675f916-dfb4-4728-bf3c-ac2e8a9d5078_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/9675f916-dfb4-4728-bf3c-ac2e8a9d5078_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Hi agent"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/c549316a-0441-4bdb-af1d-7d0748604820/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Queued',
        attribute_title: null,
        chat_id: 27,
        deleted: 0,
        start_time: '2024-07-18T05:11:32',
        end_time: '2024-07-18T05:11:32',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Hi agent',
        },
        user: {
          id: '1920a069-00c0-4d94-a1bd-0e665e8d844c',
          first_name: 'Arunadevi Rathinakumar',
          last_name: 'Arunadevi Rathinakumar',
          email: 'arunadevi.r@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
      {
        id: '35f0dab2-ebd5-4eda-bc1d-209811adaf52',
        platform: 'teams',
        user_id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
        conversation_id:
          'a:1yOKstPCG33_Yj25jAIha2EJP0udmt7BKFBHYVHpn1yXXHNfnB0gNbyhvVJWI2BIt_RJrEWGzNguCwasytlq09ta9_FYAlHFMZUIU3sgifdr_Hwzi0nxDu5DZXdVh8JeX',
        feedback: null,
        meta: {
          user_id:
            '29:1hDt_1v1nVRDZARgmznDukiSbdaOgCeSmH1UbUhfHOix_ObqvqpyL4C-qq03A2hB31I-HhidV_ZI6c47Y9jNi3g',
          conversation_id:
            'a:1yOKstPCG33_Yj25jAIha2EJP0udmt7BKFBHYVHpn1yXXHNfnB0gNbyhvVJWI2BIt_RJrEWGzNguCwasytlq09ta9_FYAlHFMZUIU3sgifdr_Hwzi0nxDu5DZXdVh8JeX',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/2e14a185-adfb-4049-89bf-be8cce1ab7f0_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/2e14a185-adfb-4049-89bf-be8cce1ab7f0_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Live agent connected"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/c549316a-0441-4bdb-af1d-7d0748604820/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Closed',
        attribute_title: 'dsfsdfs',
        chat_id: 26,
        deleted: 0,
        start_time: '2024-07-18T04:22:13',
        end_time: '2024-07-18T11:34:59',
        custom_attributes: {
          erqwer: 'werqwe',
        },
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Live agent connected',
        },
        user: {
          id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
          first_name: 'Dhanasekaran T',
          last_name: 'Dhanasekaran T',
          email: 'dhanasekaran.t@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
      {
        id: 'c36fe694-b709-4da5-8c7d-0d61f0fe6508',
        platform: 'slack',
        user_id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
        conversation_id: 'D06SBT8DF42',
        feedback: null,
        meta: {
          user_id: 'U056JG43PHN',
          conversation_id: 'D06SBT8DF42',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/d517c23e-cead-47a1-b8f4-e9f6427cb3bd',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/d517c23e-cead-47a1-b8f4-e9f6427cb3bd',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message: '"Live agent connected"',
        },
        callback_url:
          'http://channel-slack_slack-api:5050/v2/agent/messages/xoxb-5184312150199-6895807997139-a5fyoG0FfbIVibz2DXiUjPNN/T055E964E5V/U056JG43PHN',
        session_status: 'InProgress',
        attribute_title: null,
        chat_id: 25,
        deleted: 0,
        start_time: '2024-07-18T04:07:05',
        end_time: '2024-07-18T12:01:11',
        custom_attributes: null,
        custom_message: 'Live agent connected',
        user: {
          id: 'f0d29747-17e1-46d1-a419-79dbf663f9c2',
          first_name: 'Dhanasekaran T',
          last_name: 'Dhanasekaran T',
          email: 'dhanasekaran.t@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
      {
        id: '8d398c07-2974-426f-afc6-74581af54ecb',
        platform: 'teams',
        user_id: '1920a069-00c0-4d94-a1bd-0e665e8d844c',
        conversation_id:
          'a:1kSA8avJD1mMdX-xAwY08u2-yjrF4xq92JOwcdLUhOqYCXpBtXPf6wlxm6SNg67q-fQ2SSHkEVGPZQoCfOZstgwrDPw8Qr4_8mleZCaJ8lfamf0ADyMq5nb7nOXiO4Ll_',
        feedback: null,
        meta: {
          user_id:
            '29:1iUYxt3shwY6cbK1EPhmgkIRhenYIpOMy4NYZnuO4GaBqlQVT3-oIYr4IMu_mZnGL9YTY1BIoRJyIqnof4ts1pg',
          conversation_id:
            'a:1kSA8avJD1mMdX-xAwY08u2-yjrF4xq92JOwcdLUhOqYCXpBtXPf6wlxm6SNg67q-fQ2SSHkEVGPZQoCfOZstgwrDPw8Qr4_8mleZCaJ8lfamf0ADyMq5nb7nOXiO4Ll_',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/8299cf97-c661-4ca1-be4a-6dc45e285de4_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/8299cf97-c661-4ca1-be4a-6dc45e285de4_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Hi agent"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/c549316a-0441-4bdb-af1d-7d0748604820/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Closed',
        attribute_title: null,
        chat_id: 24,
        deleted: 0,
        start_time: '2024-07-17T17:15:30',
        end_time: '2024-07-18T05:10:43',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Hi agent',
        },
        user: {
          id: '1920a069-00c0-4d94-a1bd-0e665e8d844c',
          first_name: 'Arunadevi Rathinakumar',
          last_name: 'Arunadevi Rathinakumar',
          email: 'arunadevi.r@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
      {
        id: 'b0d3dc4f-8f14-4373-984f-f0c653542488',
        platform: 'teams',
        user_id: 'e7537abd-1976-4327-81a3-49b7bd7c9cc7',
        conversation_id:
          'a:1O0ukDDh8RNmKrzrhsEJwENmdi11aATmtXOG8lB2euNegEtJCcU2t2CEMaXQOmFGNDoc6TB5SAwVdvDM1Y08zWYvry00qAEKPA8PHXPZLJK01xj1jCNbjgH_95ZNUB9sG',
        feedback: null,
        meta: {
          user_id:
            '29:19aVcT4gOwXpuGr4kW9iCIlrBh7lcpGH0gxE7EAAqxb2wLmkXVOLZw2fJMQjzNf_mf5sBaplolPJg-kKzLyJ-eQ',
          conversation_id:
            'a:1O0ukDDh8RNmKrzrhsEJwENmdi11aATmtXOG8lB2euNegEtJCcU2t2CEMaXQOmFGNDoc6TB5SAwVdvDM1Y08zWYvry00qAEKPA8PHXPZLJK01xj1jCNbjgH_95ZNUB9sG',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/166ca5d7-6235-4ab3-a0d5-2a6eafaeae31/4ce67eed-2c8e-40f4-a901-8a3d55a5464c_166ca5d7-6235-4ab3-a0d5-2a6eafaeae31_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/166ca5d7-6235-4ab3-a0d5-2a6eafaeae31/4ce67eed-2c8e-40f4-a901-8a3d55a5464c_166ca5d7-6235-4ab3-a0d5-2a6eafaeae31_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Hi Agent"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/e63937fd-7d2f-45a1-9a27-ced2c8306f3d/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Queued',
        attribute_title: null,
        chat_id: 23,
        deleted: 0,
        start_time: '2024-07-17T16:57:04',
        end_time: '2024-07-17T16:57:04',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Hi Agent',
        },
        user: {
          id: 'e7537abd-1976-4327-81a3-49b7bd7c9cc7',
          first_name: 'Shri Ghayathri Ramesh',
          last_name: 'Shri Ghayathri Ramesh',
          email: 'shri.Ghayathri@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: '166ca5d7-6235-4ab3-a0d5-2a6eafaeae31',
          name: 'TestUI',
        },
      },
      {
        id: 'f544992d-e51a-42b1-9ce8-b18ec2dc979e',
        platform: 'teams',
        user_id: '1920a069-00c0-4d94-a1bd-0e665e8d844c',
        conversation_id:
          'a:1kSA8avJD1mMdX-xAwY08u2-yjrF4xq92JOwcdLUhOqYCXpBtXPf6wlxm6SNg67q-fQ2SSHkEVGPZQoCfOZstgwrDPw8Qr4_8mleZCaJ8lfamf0ADyMq5nb7nOXiO4Ll_',
        feedback: null,
        meta: {
          user_id:
            '29:1iUYxt3shwY6cbK1EPhmgkIRhenYIpOMy4NYZnuO4GaBqlQVT3-oIYr4IMu_mZnGL9YTY1BIoRJyIqnof4ts1pg',
          conversation_id:
            'a:1kSA8avJD1mMdX-xAwY08u2-yjrF4xq92JOwcdLUhOqYCXpBtXPf6wlxm6SNg67q-fQ2SSHkEVGPZQoCfOZstgwrDPw8Qr4_8mleZCaJ8lfamf0ADyMq5nb7nOXiO4Ll_',
          user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/a8a57d37-2d83-4aee-8cd5-eb1722f07ed0_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          agent_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/dhana/transcript/agent/f1cf3d70-a17e-49f9-bcc2-a762d08f65a1/a8a57d37-2d83-4aee-8cd5-eb1722f07ed0_f1cf3d70-a17e-49f9-bcc2-a762d08f65a1_teams',
          location: 'India',
          timezone: 'Asia/Kolkata',
          custom_message:
            '{"markdown_text":"normal_text","normal_text":"Hi agent"}',
        },
        callback_url:
          'http://channel-teams_api:5050/v2/agent/messages/c549316a-0441-4bdb-af1d-7d0748604820/fe8f0076-8dbf-4684-9f73-b389c33b2158',
        session_status: 'Closed',
        attribute_title: null,
        chat_id: 21,
        deleted: 0,
        start_time: '2024-07-17T15:06:10',
        end_time: '2024-07-17T15:47:53',
        custom_attributes: null,
        custom_message: {
          markdown_text: 'normal_text',
          normal_text: 'Hi agent',
        },
        user: {
          id: '1920a069-00c0-4d94-a1bd-0e665e8d844c',
          first_name: 'Arunadevi Rathinakumar',
          last_name: 'Arunadevi Rathinakumar',
          email: 'arunadevi.r@workativ.com',
          deleted: 0,
        },
        assigned_team: null,
        assigned_agent: null,
        tags: null,
        workspace: 'dhana',
        chat_source: {
          id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
          name: 'IT Support Bot',
        },
      },
    ],
    number_of_pages: 3,
    current_page: 0,
    total_count: 30,
  },
  chats_in_queue: 6,
  my_chats: 2,
  mentions: 0,
  open: 0,
  closed: 1,
};
export const mockAgent = [
  {
    id: '74018c07-42ba-11ef-aabe-6a4b5d2a749f',
    auth_user_id: 349,
    first_name: 'Aishika',
    last_name: 'Nandy',
    email: 'aishika.n@workativ.com',
    agent_status: 'Offline',
    role: 'super_admin',
    deleted: 0,
    teams: [
      {
        id: '5ed149ab-e907-4929-8f8d-b91a5758d667',
        name: 'Test',
      },
      {
        id: 'bd9561ab-d520-4ba7-96ed-21e352a1aef6',
        name: 'IT team',
      },
    ],
    chat_sources: [
      {
        id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
        name: 'IT Support Bot',
      },
      {
        id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
        name: 'IT Support Bot',
      },
      {
        id: 'fffe707f-5cf4-4244-9e73-26a6bb36c6e9',
        name: 'goto-tech-2',
      },
    ],
  },
  {
    id: '368d8bff-9c69-44e7-9cc3-629f2e3034bc',
    auth_user_id: 339,
    first_name: 'T',
    last_name: 'Dhanasekaran',
    email: 'dhanasekaran.t@workativ.com',
    agent_status: 'Online',
    role: 'shared_inbox_admin',
    deleted: 0,
    teams: [
      {
        id: '5ed149ab-e907-4929-8f8d-b91a5758d667',
        name: 'Test',
      },
      {
        id: 'bd9561ab-d520-4ba7-96ed-21e352a1aef6',
        name: 'IT team',
      },
    ],
    chat_sources: [
      {
        id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
        name: 'IT Support Bot',
      },
      {
        id: 'f1cf3d70-a17e-49f9-bcc2-a762d08f65a1',
        name: 'IT Support Bot',
      },
      {
        id: 'fffe707f-5cf4-4244-9e73-26a6bb36c6e9',
        name: 'goto-tech-2',
      },
    ],
  },
  {
    id: '258d8bff-9c69-44e7-9cc3-629f2e3034ab',
    auth_user_id: 36,
    first_name: 'Vinod',
    last_name: 'M',
    email: 'vinod.m@workativ.com',
    agent_status: 'Offline',
    role: 'shared_inbox_agent',
    deleted: 0,
    teams: [],
    chat_sources: [],
  },
];

export const mockTagList = [
  {
    id: 3,
    tag: 'IT Support Bot',
  },
  {
    id: 4,
    tag: 'IT Bot',
  },
  {
    id: 6,
    tag: 'HR Bot',
  },
  {
    id: 7,
    tag: 'HR2 Bot',
  },
  {
    id: 10,
    tag: 'check',
  },
  {
    id: 11,
    tag: '',
  },
];

export type UserInteractionT = {
  total_interaction: number;
  last_interaction: string;
  average_time: string;
  satisfied: number;
  not_satisfied: number;
};
export const mockUserData: UserInteractionT = {
  total_interaction: 1,
  last_interaction: '2024-02-06 11:35:58',
  average_time: '00:30',
  satisfied: 0,
  not_satisfied: 1,
};
export const mentions: MentionData[] = [
  {
    name: 'Manjula',
  },
  {
    name: 'Ram',
  },
  {
    name: 'Raji',
  },
  {
    name: 'Paul',
  },
  {
    name: 'Peter',
  },
];

export interface eventType {
  event_id: string;
  event_type: ReceiveEvents;
  session_id: string;
  message: MarkdownMdObject | Feedback;
  attachments: Object;
  delivery_status: string;
  created_at: Date;
  agent_username: string;
  agent_email: string;
  agent_id: string;
  user_firstname: string;
  user_lastname: string;
  user_id: string;
  user_email: string;
  chat_id: number;
  bot_id: string;
  platform: string;
}
export type OptionsT = {
  label: String;
};
export type optionT = {
  response_type: 'option';
  title: String;
  options: OptionsT[];
};

export type textT = {
  response_type: 'text';
  text: {
    text: MarkdownTextObject;
  };
};

type outputT = optionT | textT;
export interface teamsType {
  text: String;
  intents: String[];
  entities: String[];
  context: any;
  output: outputT[];
  masked: boolean;
  faq: any[];
  req_id?: string;
  userMessageTime: string;
  botMessageTime: string;
}
export const eventList: eventType[] = [
  {
    event_id: '2357867b-8dd0-4cc8-a366-e1d6b2109dc5',
    event_type: ReceiveEvents.AgentMessage,
    session_id: 'fa9c194d-d73a-400a-b01f-d50ddf966265',
    message: {
      md_type: 'md_normal',
      value: 'Hi',
    },
    attachments: {},
    delivery_status: '',
    created_at: '2024-02-13T09:51:28.602Z',
    agent_username: 'Aruna',
    agent_email: 'aruna@Workativ.com',
    agent_id: '39',
    user_firstname: 'Paul',
    user_lastname: 'jacob',
    user_id: 'ff414f18-60f8-4558-a999-71d137c80fdd',
    user_email: 'paul_Jacob@gmail.com',
    chat_id: 4,
  },
  {
    event_id: '2357867b-8dd0-4cc8-a366-e1d6b2109dc5',
    event_type: ReceiveEvents.UserMessage,
    session_id: 'fa9c194d-d73a-400a-b01f-d50ddf966265',
    message: {
      md_type: 'md_normal',
      value: 'Hi',
    },
    attachments: {},
    delivery_status: '',
    created_at: '2024-02-13T09:51:28.602Z',
    agent_username: 'Aruna',
    agent_email: 'aruna@Workativ.com',
    agent_id: '39',
    user_firstname: 'Paul',
    user_lastname: 'jacob',
    user_id: 'ff414f18-60f8-4558-a999-71d137c80fdd',
    user_email: 'paul_Jacob@gmail.com',
    chat_id: 4,
  },
  {
    event_id: '2357867b-8dd0-4cc8-a366-e1d6b2109dc5',
    event_type: ReceiveEvents.AgentMessage,
    session_id: 'fa9c194d-d73a-400a-b01f-d50ddf966265',
    message: {
      md_type: 'md_normal',
      value: 'Hi',
    },
    attachments: {},
    delivery_status: 'delivery_status',
    created_at: '2024-02-13T09:51:28.602Z',
    agent_username: 'Aruna',
    agent_email: 'aruna@Workativ.com',
    agent_id: '39',
    user_firstname: 'Paul',
    user_lastname: 'jacob',
    user_id: 'ff414f18-60f8-4558-a999-71d137c80fdd',
    user_email: 'paul_Jacob@gmail.com',
    chat_id: 4,
  },

  // {
  //   AgentMessage: {
  //     id: '2357867b-8dd0-4cc8-a366-e1d6b2109dc5',
  //     time: 'Tue Feb 13 2024 15:21:28 GMT+0530 (India Standard Time)',
  //     room_id: 'manjulaworkspace',
  //     event_type: 'agent_message',
  //     data: {
  //       id: 'ff414f18-60f8-4558-a999-71d137c80fdd',
  //       session_id: 'fa9c194d-d73a-400a-b01f-d50ddf966265',
  //       agent_id: '39',
  //       message: {
  //         md_type: 'md_normal',
  //         value: 'Hi',
  //       },
  //       message_time: '2024-02-13T09:51:28.602Z',
  //       delivery_status: 1,
  //       deleted: 0,
  //     },
  //   },
  // },
  // {
  //   AgentMessage: {
  //     id: 'e644e189-5ac5-4bce-9d74-91e7a44fe864',
  //     time: 'Tue Feb 13 2024 15:22:28 GMT+0530 (India Standard Time)',
  //     room_id: 'manjulaworkspace',
  //     event_type: 'agent_message',
  //     data: {
  //       id: '20dc8776-55c2-410c-a1b0-ab7f19b23202',
  //       session_id: 'fa9c194d-d73a-400a-b01f-d50ddf966265',
  //       agent_id: '39',
  //       message: {
  //         md_type: 'md_normal',
  //         value: 'How can I help you?',
  //       },
  //       message_time: '2024-02-13T09:52:28.748Z',
  //       delivery_status: 1,
  //       deleted: 0,
  //     },
  //   },
  // },
  // {
  //   AgentMention: {
  //     id: '6d5faf7d-5320-48d7-be61-8d3ab838d04b',
  //     time: '2024-02-13 09:57:04.232283565 UTC',
  //     room_id: 'manjulaworkspace',
  //     event_type: 'agent_mention',
  //     data: {
  //       id: '0ef62953-e988-46a7-b33e-fb616f31185a',
  //       agent_id: 39,
  //       session_id: 'ec32c4a2-4119-48c1-8584-c6c1e9fa02bc',
  //       mention: ['d4a9c178-44a2-450c-a1be-872380d07c5a'],
  //       message: {
  //         lhs: {
  //           md_type: 'md_mention',
  //           value: {
  //             id: 'd4a9c178-44a2-450c-a1be-872380d07c5a',
  //             name: 'Arunadevi Rathinakumar',
  //           },
  //         },
  //         md_type: 'md_join',
  //         rhs: {
  //           md_type: 'md_normal',
  //           value: ' ',
  //         },
  //       },
  //       message_time: '2024-02-13 09:57:04.232283565 UTC',
  //       delivery_status: 1,
  //     },
  //   },
  // },
  // {
  //   AgentComment: {
  //     id: 'bcd44ebc-498d-4951-b8fc-a87a0cb7df35',
  //     time: '2024-02-13 09:57:37.014765209 UTC',
  //     room_id: 'manjulaworkspace',
  //     event_type: 'agent_comment',
  //     data: {
  //       id: '1923afe1-52de-45fc-9525-a045cbb5155c',
  //       agent_id: 39,
  //       session_id: 'ec32c4a2-4119-48c1-8584-c6c1e9fa02bc',
  //       message: {
  //         md_type: 'md_normal',
  //         value: 'Checking Comment',
  //       },
  //       message_time: '2024-02-13 09:57:37.014775505 UTC',
  //       delivery_status: 1,
  //     },
  //   },
  // },
];
export const notificationEvent = {
  event_type: 'queue_notification',
  id: '3d7c2a4d-bb9e-4557-ab43-179df0328a63',
  time: '2024-07-11 06:43:02.020090700',
  workspace: 'nirup',
  data: {
    id: '5c128108-aadb-4f0b-942d-5604248a1440',
    session_id: '50514334-4ede-4c3b-99b9-931113cfde8d',
    message: {
      markdown_text: 'normal_text',
      normal_text: 'Hello agent!',
    },
    bot_id: '9bc32e3a-89f4-4402-a061-d96766d15708',
    platform: 'teams',
    workspace: 'nirup',
    name: 'Shri Ghayathri Ramesh',
    email: 'shri.Ghayathri@workativ.com',
    attachments: [],
    message_time: '2024-07-11 06:42:58',
    delivery_status: 1,
    deleted: 0,
  },
};
export const initialNotificationEvent = {
  event_type: 'queue_notification',
  id: '',
  time: '',
  workspace: '',
  data: {
    id: '',
    session_id: '',
    message: {
      markdown_text: 'normal_text',
      normal_text: 'Hello agent!',
    },
    bot_id: '',
    platform: '',
    workspace: '',
    name: '',
    email: '',
    attachments: [],
    message_time: '',
    delivery_status: 0,
    deleted: 0,
  },
};
export const teamConversation = [
  {
    _id: '65e047e56b9a600056d28b2e',
    req_id: '46234e40-f741-4b14-8664-b272e1b2d899',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: null,
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e047e56b9a600056d28b2f',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: '',
      intents: [],
      entities: [],
      faq: [],
      context_variables: {
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_Timezone: null,
        SYS_SETTINGS: {
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          feedback_message: 'Please provide your feedback',
          knowledge_ai_bases: null,
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          disambigutation_nota_option_text: 'None of the above',
          knowledge_ai_source: null,
          session_termination_reminder_time: 3,
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          enable_disambiguation: true,
          end_conversation_time: 5,
          session_termination_reminder_message: 'Your session is about to end',
          disambigutation_nota_enabled: true,
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          disambigutation_nota_handle_node: null,
          created_at: '2022-01-10T08:31:54',
          satisfied_feedback: 'Thank you for your valuable feedback',
          disambiguation_message: 'What do you want to do?',
          updated_at: '2024-02-07T11:35:12',
          end_conversation_message:
            'Your previous session has ended. Please start again',
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
        },
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_TEAMS_CONFIG: {
          TIME_THRESHOLD: 5,
          WATSON_LAST_RESPONSE_TIMESTAMP: null,
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
        },
        SYS_LastName: 'Rathinakumar',
        SYS_FirstName: 'Arunadevi',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_platform: 'teams',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        metadata: {
          deployment: 'teams',
        },
      },
      system: {
        stack: [],
        _id: '65e047e56b9a600056d28b30',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: false,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:01:25.082Z',
        _id: '65e047ea6b9a600056d28b35',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: '',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_current_root: 'Welcome',
          SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_LastName: 'Rathinakumar',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_User_Latest_Response: '',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_SETTINGS: {
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            feedback_message: 'Please provide your feedback',
            knowledge_ai_bases: null,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            disambigutation_nota_option_text: 'None of the above',
            knowledge_ai_source: null,
            session_termination_reminder_time: 3,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            enable_disambiguation: true,
            end_conversation_time: 5,
            session_termination_reminder_message:
              'Your session is about to end',
            disambigutation_nota_enabled: true,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            disambigutation_nota_handle_node: null,
            created_at: '2022-01-10T08:31:54',
            satisfied_feedback: 'Thank you for your valuable feedback',
            disambiguation_message: 'What do you want to do?',
            updated_at: '2024-02-07T11:35:12',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          },
          SYS_Timezone: null,
          metadata: {
            deployment: 'teams',
          },
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_TEAMS_CONFIG: {
            TIME_THRESHOLD: 5,
            WATSON_LAST_RESPONSE_TIMESTAMP: null,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          },
          SYS_FirstName: 'Arunadevi',
          SYS_current_dialog_title: 'Welcome',
          SYS_platform: 'teams',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
        },
        system: {
          stack: ['33a13cba-bf04-47f2-9d37-b70b3e937374'],
          _id: '65e047ea6b9a600056d28b36',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_join',
                lhs: {
                  md_type: 'md_normal',
                  value: 'Hi ',
                },
                rhs: {
                  md_type: 'md_join',
                  lhs: {
                    md_type: 'md_normal',
                    value: 'Arunadevi',
                  },
                  rhs: {
                    md_type: 'md_normal',
                    value: ', how can i help you?',
                  },
                },
              },
            },
          },
        ],
        created_at: '2024-02-29T09:01:25.108Z',
        _id: '65e047ef6b9a600056d28b3d',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: '',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_current_root: 'Welcome',
          SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_LastName: 'Rathinakumar',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_User_Latest_Response: '',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_SETTINGS: {
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            feedback_message: 'Please provide your feedback',
            knowledge_ai_bases: null,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            disambigutation_nota_option_text: 'None of the above',
            knowledge_ai_source: null,
            session_termination_reminder_time: 3,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            enable_disambiguation: true,
            end_conversation_time: 5,
            session_termination_reminder_message:
              'Your session is about to end',
            disambigutation_nota_enabled: true,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            disambigutation_nota_handle_node: null,
            created_at: '2022-01-10T08:31:54',
            satisfied_feedback: 'Thank you for your valuable feedback',
            disambiguation_message: 'What do you want to do?',
            updated_at: '2024-02-07T11:35:12',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          },
          SYS_Timezone: null,
          metadata: {
            deployment: 'teams',
          },
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_TEAMS_CONFIG: {
            TIME_THRESHOLD: 5,
            WATSON_LAST_RESPONSE_TIMESTAMP: null,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          },
          SYS_FirstName: 'Arunadevi',
          SYS_current_dialog_title: 'Welcome',
          SYS_platform: 'teams',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
        },
        system: {
          stack: ['transient-node-33a13cba-bf04-47f2-9d37-b70b3e937374-0'],
          _id: '65e047ef6b9a600056d28b3e',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:01:24.972Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [],
  },
  {
    _id: '65e047ef6b9a600056d28b41',
    req_id: '4a4a3a6a-cf1e-452c-83f8-a695f345e987',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '62b06ffd059932a2fcb72586',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e047ef6b9a600056d28b42',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'hi',
      intents: [
        {
          _id: '65e047ef6b9a600056d28b43',
          intent: 'Greetings',
          confidence: 1,
        },
      ],
      entities: [
        {
          location: [0, 2],
          _id: '65e047ef6b9a600056d28b44',
          entity: 'email',
          value: 'manjula',
          confidence: 0,
        },
        {
          location: [0, 2],
          _id: '65e047ef6b9a600056d28b45',
          entity: 'greet',
          value: 'greet',
          confidence: 0,
        },
      ],
      faq: [],
      context_variables: {
        SYS_TEAMS_CONFIG: {
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          TIME_THRESHOLD: 5,
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197285140,
        },
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_Timezone: null,
        SYS_current_dialog_title: 'Welcome',
        SYS_current_root: 'Welcome',
        metadata: {
          deployment: 'teams',
        },
        SYS_User_Latest_Response: '',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_platform: 'teams',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_LastName: 'Rathinakumar',
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_FirstName: 'Arunadevi',
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_SETTINGS: {
          knowledge_ai_bases: null,
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          end_conversation_time: 5,
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          created_at: '2022-01-10T08:31:54',
          session_termination_reminder_time: 3,
          satisfied_feedback: 'Thank you for your valuable feedback',
          enable_disambiguation: true,
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          disambiguation_message: 'What do you want to do?',
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          disambigutation_nota_enabled: true,
          end_conversation_message:
            'Your previous session has ended. Please start again',
          feedback_message: 'Please provide your feedback',
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          knowledge_ai_source: null,
          disambigutation_nota_handle_node: null,
          session_termination_reminder_message: 'Your session is about to end',
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          disambigutation_nota_option_text: 'None of the above',
          updated_at: '2024-02-07T11:35:12',
        },
      },
      system: {
        stack: ['transient-node-33a13cba-bf04-47f2-9d37-b70b3e937374-0'],
        _id: '65e047ef6b9a600056d28b46',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:01:34.903Z',
        _id: '65e047f46b9a600056d28b4e',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'hi',
        intents: [
          {
            _id: '65e047f46b9a600056d28b4f',
            intent: 'Greetings',
            confidence: 1,
          },
        ],
        entities: [
          {
            location: [0, 2],
            _id: '65e047f46b9a600056d28b50',
            entity: 'email',
            value: 'manjula',
            confidence: 0,
          },
          {
            location: [0, 2],
            _id: '65e047f46b9a600056d28b51',
            entity: 'greet',
            value: 'greet',
            confidence: 0,
          },
        ],
        faq: [],
        context_variables: {
          SYS_previous_dialog_title: 'Welcome',
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197285140,
          },
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_Timezone: null,
          SYS_current_dialog_title: 'Welcome',
          SYS_current_root: 'Welcome',
          metadata: {
            deployment: 'teams',
          },
          SYS_User_Latest_Response: 'hi',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_platform: 'teams',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_LastName: 'Rathinakumar',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_FirstName: 'Arunadevi',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_SETTINGS: {
            knowledge_ai_bases: null,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            end_conversation_time: 5,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            created_at: '2022-01-10T08:31:54',
            session_termination_reminder_time: 3,
            satisfied_feedback: 'Thank you for your valuable feedback',
            enable_disambiguation: true,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            disambiguation_message: 'What do you want to do?',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            disambigutation_nota_enabled: true,
            end_conversation_message:
              'Your previous session has ended. Please start again',
            feedback_message: 'Please provide your feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            knowledge_ai_source: null,
            disambigutation_nota_handle_node: null,
            session_termination_reminder_message:
              'Your session is about to end',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            disambigutation_nota_option_text: 'None of the above',
            updated_at: '2024-02-07T11:35:12',
          },
        },
        system: {
          stack: ['33a13cba-bf04-47f2-9d37-b70b3e937374'],
          _id: '65e047f46b9a600056d28b52',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_join',
                lhs: {
                  md_type: 'md_normal',
                  value: 'Hi ',
                },
                rhs: {
                  md_type: 'md_join',
                  lhs: {
                    md_type: 'md_normal',
                    value: 'Arunadevi',
                  },
                  rhs: {
                    md_type: 'md_normal',
                    value: ', how can i help you?',
                  },
                },
              },
            },
          },
        ],
        created_at: '2024-02-29T09:01:34.931Z',
        _id: '65e047fa6b9a600056d28b59',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'hi',
        intents: [
          {
            _id: '65e047fa6b9a600056d28b5a',
            intent: 'Greetings',
            confidence: 1,
          },
        ],
        entities: [
          {
            location: [0, 2],
            _id: '65e047fa6b9a600056d28b5b',
            entity: 'email',
            value: 'manjula',
            confidence: 0,
          },
          {
            location: [0, 2],
            _id: '65e047fa6b9a600056d28b5c',
            entity: 'greet',
            value: 'greet',
            confidence: 0,
          },
        ],
        faq: [],
        context_variables: {
          SYS_previous_dialog_title: 'Welcome',
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197285140,
          },
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_Timezone: null,
          SYS_current_dialog_title: 'Welcome',
          SYS_current_root: 'Welcome',
          metadata: {
            deployment: 'teams',
          },
          SYS_User_Latest_Response: 'hi',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_platform: 'teams',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_LastName: 'Rathinakumar',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_FirstName: 'Arunadevi',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_SETTINGS: {
            knowledge_ai_bases: null,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            end_conversation_time: 5,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            created_at: '2022-01-10T08:31:54',
            session_termination_reminder_time: 3,
            satisfied_feedback: 'Thank you for your valuable feedback',
            enable_disambiguation: true,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            disambiguation_message: 'What do you want to do?',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            disambigutation_nota_enabled: true,
            end_conversation_message:
              'Your previous session has ended. Please start again',
            feedback_message: 'Please provide your feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            knowledge_ai_source: null,
            disambigutation_nota_handle_node: null,
            session_termination_reminder_message:
              'Your session is about to end',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            disambigutation_nota_option_text: 'None of the above',
            updated_at: '2024-02-07T11:35:12',
          },
        },
        system: {
          stack: ['transient-node-33a13cba-bf04-47f2-9d37-b70b3e937374-0'],
          _id: '65e047fa6b9a600056d28b5d',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:01:34.868Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [
      {
        _id: '65e047ef6b9a600056d28b47',
        intent: 'Greetings',
        log_id: '65e047ef6b9a600056d28b41',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        time_stamp: '2024-02-29T09:01:34.868Z',
        __v: 0,
      },
    ],
  },
  {
    _id: '65e047fa6b9a600056d28b60',
    req_id: 'aa4beeca-55c5-45fd-8960-862a1b3b7d3b',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '65e047fa1c0fb517140719ff',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e047fa6b9a600056d28b61',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'request',
      intents: [],
      entities: [],
      faq: [],
      context_variables: {
        SYS_TEAMS_CONFIG: {
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197295050,
          TIME_THRESHOLD: 5,
        },
        SYS_FirstName: 'Arunadevi',
        SYS_User_Latest_Response: 'hi',
        SYS_platform: 'teams',
        SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_SETTINGS: {
          enable_disambiguation: true,
          knowledge_ai_bases: null,
          satisfied_feedback: 'Thank you for your valuable feedback',
          end_conversation_time: 5,
          disambigutation_nota_option_text: 'None of the above',
          session_termination_reminder_time: 3,
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          disambigutation_nota_enabled: true,
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          session_termination_reminder_message: 'Your session is about to end',
          end_conversation_message:
            'Your previous session has ended. Please start again',
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          disambiguation_message: 'What do you want to do?',
          disambigutation_nota_handle_node: null,
          created_at: '2022-01-10T08:31:54',
          updated_at: '2024-02-07T11:35:12',
          knowledge_ai_source: null,
          feedback_message: 'Please provide your feedback',
        },
        SYS_LastName: 'Rathinakumar',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_Timezone: null,
        SYS_current_root: 'Welcome',
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        SYS_previous_root_dialog_type: 'IF_NODE',
        SYS_current_dialog_title: 'Welcome',
        metadata: {
          deployment: 'teams',
        },
        SYS_previous_dialog_title: 'Welcome',
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
      },
      system: {
        stack: ['transient-node-33a13cba-bf04-47f2-9d37-b70b3e937374-0'],
        _id: '65e047fa6b9a600056d28b62',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:01:44.813Z',
        _id: '65e047ff6b9a600056d28b68',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'request',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197295050,
            TIME_THRESHOLD: 5,
          },
          SYS_FirstName: 'Arunadevi',
          SYS_User_Latest_Response: 'request',
          SYS_platform: 'teams',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_SETTINGS: {
            enable_disambiguation: true,
            knowledge_ai_bases: null,
            satisfied_feedback: 'Thank you for your valuable feedback',
            end_conversation_time: 5,
            disambigutation_nota_option_text: 'None of the above',
            session_termination_reminder_time: 3,
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            disambigutation_nota_enabled: true,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            disambiguation_message: 'What do you want to do?',
            disambigutation_nota_handle_node: null,
            created_at: '2022-01-10T08:31:54',
            updated_at: '2024-02-07T11:35:12',
            knowledge_ai_source: null,
            feedback_message: 'Please provide your feedback',
          },
          SYS_LastName: 'Rathinakumar',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_Timezone: null,
          SYS_current_root: 'Fallback',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_current_dialog_title: 'Fallback',
          metadata: {
            deployment: 'teams',
          },
          SYS_previous_dialog_title: 'Welcome',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        },
        system: {
          stack: ['fa08d90d-2dba-4ec2-95ef-223c13ae7180'],
          _id: '65e047ff6b9a600056d28b69',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_normal',
                value: "Oops! I didn't get you",
              },
            },
          },
        ],
        created_at: '2024-02-29T09:01:44.838Z',
        _id: '65e048046b9a600056d28b70',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'request',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197295050,
            TIME_THRESHOLD: 5,
          },
          SYS_FirstName: 'Arunadevi',
          SYS_User_Latest_Response: 'request',
          SYS_platform: 'teams',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_SETTINGS: {
            enable_disambiguation: true,
            knowledge_ai_bases: null,
            satisfied_feedback: 'Thank you for your valuable feedback',
            end_conversation_time: 5,
            disambigutation_nota_option_text: 'None of the above',
            session_termination_reminder_time: 3,
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            disambigutation_nota_enabled: true,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            disambiguation_message: 'What do you want to do?',
            disambigutation_nota_handle_node: null,
            created_at: '2022-01-10T08:31:54',
            updated_at: '2024-02-07T11:35:12',
            knowledge_ai_source: null,
            feedback_message: 'Please provide your feedback',
          },
          SYS_LastName: 'Rathinakumar',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_Timezone: null,
          SYS_current_root: 'Fallback',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_current_dialog_title: 'Fallback',
          metadata: {
            deployment: 'teams',
          },
          SYS_previous_dialog_title: 'Welcome',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        },
        system: {
          stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
          _id: '65e048046b9a600056d28b71',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:01:44.784Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [],
  },
  {
    _id: '65e048056b9a600056d28b74',
    req_id: 'bfaac3ab-7119-4bbf-af15-75918a91a862',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '65e048051c0fb51714073303',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e048056b9a600056d28b75',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'help',
      intents: [
        {
          _id: '65e048056b9a600056d28b76',
          intent: 'ok',
          confidence: 0.48,
        },
      ],
      entities: [],
      faq: [],
      context_variables: {
        SYS_SETTINGS: {
          updated_at: '2024-02-07T11:35:12',
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          session_termination_reminder_message: 'Your session is about to end',
          end_conversation_message:
            'Your previous session has ended. Please start again',
          feedback_message: 'Please provide your feedback',
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          created_at: '2022-01-10T08:31:54',
          disambigutation_nota_handle_node: null,
          disambigutation_nota_option_text: 'None of the above',
          knowledge_ai_bases: null,
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          end_conversation_time: 5,
          disambiguation_message: 'What do you want to do?',
          knowledge_ai_source: null,
          disambigutation_nota_enabled: true,
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          satisfied_feedback: 'Thank you for your valuable feedback',
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          session_termination_reminder_time: 3,
          enable_disambiguation: true,
        },
        SYS_Timezone: null,
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_User_Latest_Response: 'request',
        SYS_previous_dialog_title: 'Welcome',
        SYS_LastName: 'Rathinakumar',
        SYS_previous_root_dialog_type: 'IF_NODE',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
        SYS_TEAMS_CONFIG: {
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          TIME_THRESHOLD: 5,
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197304981,
        },
        SYS_FirstName: 'Arunadevi',
        SYS_current_dialog_title: 'Fallback',
        metadata: {
          deployment: 'teams',
        },
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_platform: 'teams',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_current_root: 'Fallback',
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_UserEmail: 'arunadevi.r@workativ.com',
      },
      system: {
        stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
        _id: '65e048056b9a600056d28b77',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:01:52.703Z',
        _id: '65e0480a6b9a600056d28b7d',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'help',
        intents: [
          {
            _id: '65e0480a6b9a600056d28b7e',
            intent: 'ok',
            confidence: 0.48,
          },
        ],
        entities: [],
        faq: [],
        context_variables: {
          SYS_SETTINGS: {
            updated_at: '2024-02-07T11:35:12',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            feedback_message: 'Please provide your feedback',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            created_at: '2022-01-10T08:31:54',
            disambigutation_nota_handle_node: null,
            disambigutation_nota_option_text: 'None of the above',
            knowledge_ai_bases: null,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            end_conversation_time: 5,
            disambiguation_message: 'What do you want to do?',
            knowledge_ai_source: null,
            disambigutation_nota_enabled: true,
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            satisfied_feedback: 'Thank you for your valuable feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            session_termination_reminder_time: 3,
            enable_disambiguation: true,
          },
          SYS_Timezone: null,
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_User_Latest_Response: 'help',
          SYS_previous_dialog_title: 'Fallback',
          SYS_LastName: 'Rathinakumar',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197304981,
          },
          SYS_FirstName: 'Arunadevi',
          SYS_current_dialog_title: 'Fallback',
          metadata: {
            deployment: 'teams',
          },
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_platform: 'teams',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_current_root: 'Fallback',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
        },
        system: {
          stack: ['fa08d90d-2dba-4ec2-95ef-223c13ae7180'],
          _id: '65e0480a6b9a600056d28b7f',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_normal',
                value: "Oops! I didn't get you",
              },
            },
          },
        ],
        created_at: '2024-02-29T09:01:52.741Z',
        _id: '65e0480f6b9a600056d28b86',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'help',
        intents: [
          {
            _id: '65e0480f6b9a600056d28b87',
            intent: 'ok',
            confidence: 0.48,
          },
        ],
        entities: [],
        faq: [],
        context_variables: {
          SYS_SETTINGS: {
            updated_at: '2024-02-07T11:35:12',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            feedback_message: 'Please provide your feedback',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            created_at: '2022-01-10T08:31:54',
            disambigutation_nota_handle_node: null,
            disambigutation_nota_option_text: 'None of the above',
            knowledge_ai_bases: null,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            end_conversation_time: 5,
            disambiguation_message: 'What do you want to do?',
            knowledge_ai_source: null,
            disambigutation_nota_enabled: true,
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            satisfied_feedback: 'Thank you for your valuable feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            session_termination_reminder_time: 3,
            enable_disambiguation: true,
          },
          SYS_Timezone: null,
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_User_Latest_Response: 'help',
          SYS_previous_dialog_title: 'Fallback',
          SYS_LastName: 'Rathinakumar',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197304981,
          },
          SYS_FirstName: 'Arunadevi',
          SYS_current_dialog_title: 'Fallback',
          metadata: {
            deployment: 'teams',
          },
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_platform: 'teams',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_current_root: 'Fallback',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
        },
        system: {
          stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
          _id: '65e0480f6b9a600056d28b88',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:01:52.675Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [
      {
        _id: '65e048056b9a600056d28b78',
        intent: 'ok',
        log_id: '65e048056b9a600056d28b74',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        time_stamp: '2024-02-29T09:01:52.675Z',
        __v: 0,
      },
    ],
  },
  {
    _id: '65e0481c6b9a600056d28b8b',
    req_id: 'edf76e9c-c3dd-4e60-945e-ac08787812a6',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '65e0481c1c0fb51714076a8f',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e0481c6b9a600056d28b8c',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'dddd',
      intents: [],
      entities: [],
      faq: [],
      context_variables: {
        SYS_previous_root_dialog_type: 'IF_NODE',
        SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
        SYS_previous_dialog_title: 'Fallback',
        SYS_User_Latest_Response: 'help',
        SYS_current_dialog_title: 'Fallback',
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_FirstName: 'Arunadevi',
        SYS_current_root: 'Fallback',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_platform: 'teams',
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_LastName: 'Rathinakumar',
        SYS_SETTINGS: {
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          session_termination_reminder_message: 'Your session is about to end',
          disambigutation_nota_enabled: true,
          end_conversation_message:
            'Your previous session has ended. Please start again',
          satisfied_feedback: 'Thank you for your valuable feedback',
          updated_at: '2024-02-07T11:35:12',
          disambiguation_message: 'What do you want to do?',
          disambigutation_nota_option_text: 'None of the above',
          disambigutation_nota_handle_node: null,
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          created_at: '2022-01-10T08:31:54',
          end_conversation_time: 5,
          enable_disambiguation: true,
          session_termination_reminder_time: 3,
          knowledge_ai_source: null,
          knowledge_ai_bases: null,
          feedback_message: 'Please provide your feedback',
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
        },
        SYS_Timezone: null,
        metadata: {
          deployment: 'teams',
        },
        SYS_TEAMS_CONFIG: {
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197312770,
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          TIME_THRESHOLD: 5,
        },
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
      },
      system: {
        stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
        _id: '65e0481c6b9a600056d28b8d',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:02:20.129Z',
        _id: '65e048216b9a600056d28b93',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'dddd',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_previous_dialog_title: 'Fallback',
          SYS_User_Latest_Response: 'dddd',
          SYS_current_dialog_title: 'Fallback',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_FirstName: 'Arunadevi',
          SYS_current_root: 'Fallback',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_platform: 'teams',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_LastName: 'Rathinakumar',
          SYS_SETTINGS: {
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            session_termination_reminder_message:
              'Your session is about to end',
            disambigutation_nota_enabled: true,
            end_conversation_message:
              'Your previous session has ended. Please start again',
            satisfied_feedback: 'Thank you for your valuable feedback',
            updated_at: '2024-02-07T11:35:12',
            disambiguation_message: 'What do you want to do?',
            disambigutation_nota_option_text: 'None of the above',
            disambigutation_nota_handle_node: null,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            created_at: '2022-01-10T08:31:54',
            end_conversation_time: 5,
            enable_disambiguation: true,
            session_termination_reminder_time: 3,
            knowledge_ai_source: null,
            knowledge_ai_bases: null,
            feedback_message: 'Please provide your feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
          },
          SYS_Timezone: null,
          metadata: {
            deployment: 'teams',
          },
          SYS_TEAMS_CONFIG: {
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197312770,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
          },
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        },
        system: {
          stack: ['fa08d90d-2dba-4ec2-95ef-223c13ae7180'],
          _id: '65e048216b9a600056d28b94',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_normal',
                value: "Oops! I didn't get you",
              },
            },
          },
        ],
        created_at: '2024-02-29T09:02:20.155Z',
        _id: '65e048266b9a600056d28b9b',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'dddd',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_previous_dialog_title: 'Fallback',
          SYS_User_Latest_Response: 'dddd',
          SYS_current_dialog_title: 'Fallback',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_FirstName: 'Arunadevi',
          SYS_current_root: 'Fallback',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_platform: 'teams',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_LastName: 'Rathinakumar',
          SYS_SETTINGS: {
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            session_termination_reminder_message:
              'Your session is about to end',
            disambigutation_nota_enabled: true,
            end_conversation_message:
              'Your previous session has ended. Please start again',
            satisfied_feedback: 'Thank you for your valuable feedback',
            updated_at: '2024-02-07T11:35:12',
            disambiguation_message: 'What do you want to do?',
            disambigutation_nota_option_text: 'None of the above',
            disambigutation_nota_handle_node: null,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            created_at: '2022-01-10T08:31:54',
            end_conversation_time: 5,
            enable_disambiguation: true,
            session_termination_reminder_time: 3,
            knowledge_ai_source: null,
            knowledge_ai_bases: null,
            feedback_message: 'Please provide your feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
          },
          SYS_Timezone: null,
          metadata: {
            deployment: 'teams',
          },
          SYS_TEAMS_CONFIG: {
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197312770,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
          },
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        },
        system: {
          stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
          _id: '65e048266b9a600056d28b9c',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:02:20.099Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [],
  },
  {
    _id: '65e048366b9a600056d28b9f',
    req_id: '4f24de4d-b9d5-4761-a80c-0ed6f40d8aaa',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '65e048361c0fb5171407acd1',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e048366b9a600056d28ba0',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'dwq',
      intents: [],
      entities: [],
      faq: [],
      context_variables: {
        SYS_Timezone: null,
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_current_root: 'Fallback',
        SYS_previous_dialog_title: 'Fallback',
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_LastName: 'Rathinakumar',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_FirstName: 'Arunadevi',
        SYS_platform: 'teams',
        metadata: {
          deployment: 'teams',
        },
        SYS_current_dialog_title: 'Fallback',
        SYS_previous_root_dialog_type: 'IF_NODE',
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_SETTINGS: {
          disambigutation_nota_option_text: 'None of the above',
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          updated_at: '2024-02-07T11:35:12',
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          knowledge_ai_bases: null,
          feedback_message: 'Please provide your feedback',
          session_termination_reminder_message: 'Your session is about to end',
          knowledge_ai_source: null,
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          disambigutation_nota_enabled: true,
          disambigutation_nota_handle_node: null,
          disambiguation_message: 'What do you want to do?',
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          end_conversation_time: 5,
          end_conversation_message:
            'Your previous session has ended. Please start again',
          satisfied_feedback: 'Thank you for your valuable feedback',
          session_termination_reminder_time: 3,
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          created_at: '2022-01-10T08:31:54',
          enable_disambiguation: true,
        },
        SYS_User_Latest_Response: 'dddd',
        SYS_TEAMS_CONFIG: {
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197340184,
          TIME_THRESHOLD: 5,
        },
      },
      system: {
        stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
        _id: '65e048366b9a600056d28ba1',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:02:46.638Z',
        _id: '65e0483b6b9a600056d28ba7',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'dwq',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_Timezone: null,
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_current_root: 'Fallback',
          SYS_previous_dialog_title: 'Fallback',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_LastName: 'Rathinakumar',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_FirstName: 'Arunadevi',
          SYS_platform: 'teams',
          metadata: {
            deployment: 'teams',
          },
          SYS_current_dialog_title: 'Fallback',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_SETTINGS: {
            disambigutation_nota_option_text: 'None of the above',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            updated_at: '2024-02-07T11:35:12',
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            knowledge_ai_bases: null,
            feedback_message: 'Please provide your feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            knowledge_ai_source: null,
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            disambigutation_nota_enabled: true,
            disambigutation_nota_handle_node: null,
            disambiguation_message: 'What do you want to do?',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            end_conversation_time: 5,
            end_conversation_message:
              'Your previous session has ended. Please start again',
            satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_time: 3,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            created_at: '2022-01-10T08:31:54',
            enable_disambiguation: true,
          },
          SYS_User_Latest_Response: 'dwq',
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197340184,
            TIME_THRESHOLD: 5,
          },
        },
        system: {
          stack: ['fa08d90d-2dba-4ec2-95ef-223c13ae7180'],
          _id: '65e0483b6b9a600056d28ba8',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_normal',
                value: "Oops! I didn't get you",
              },
            },
          },
        ],
        created_at: '2024-02-29T09:02:46.671Z',
        _id: '65e048416b9a600056d28baf',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'dwq',
        intents: [],
        entities: [],
        faq: [],
        context_variables: {
          SYS_Timezone: null,
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_current_root: 'Fallback',
          SYS_previous_dialog_title: 'Fallback',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_LastName: 'Rathinakumar',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_FirstName: 'Arunadevi',
          SYS_platform: 'teams',
          metadata: {
            deployment: 'teams',
          },
          SYS_current_dialog_title: 'Fallback',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_SETTINGS: {
            disambigutation_nota_option_text: 'None of the above',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            updated_at: '2024-02-07T11:35:12',
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            knowledge_ai_bases: null,
            feedback_message: 'Please provide your feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            knowledge_ai_source: null,
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            disambigutation_nota_enabled: true,
            disambigutation_nota_handle_node: null,
            disambiguation_message: 'What do you want to do?',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            end_conversation_time: 5,
            end_conversation_message:
              'Your previous session has ended. Please start again',
            satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_time: 3,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            created_at: '2022-01-10T08:31:54',
            enable_disambiguation: true,
          },
          SYS_User_Latest_Response: 'dwq',
          SYS_TEAMS_CONFIG: {
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197340184,
            TIME_THRESHOLD: 5,
          },
        },
        system: {
          stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
          _id: '65e048416b9a600056d28bb0',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:02:46.612Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [],
  },
  {
    _id: '65e0484e6b9a600056d28bb3',
    req_id: '4150c6f0-e82d-4f14-92ff-b122e6ea0132',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '62b06ffd059932a2fcb72586',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e0484e6b9a600056d28bb4',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'hi',
      intents: [
        {
          _id: '65e0484e6b9a600056d28bb5',
          intent: 'Greetings',
          confidence: 1,
        },
      ],
      entities: [
        {
          location: [0, 2],
          _id: '65e0484e6b9a600056d28bb6',
          entity: 'email',
          value: 'manjula',
          confidence: 0,
        },
        {
          location: [0, 2],
          _id: '65e0484e6b9a600056d28bb7',
          entity: 'greet',
          value: 'greet',
          confidence: 0,
        },
      ],
      faq: [],
      context_variables: {
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_FirstName: 'Arunadevi',
        SYS_current_dialog_title: 'Fallback',
        SYS_TEAMS_CONFIG: {
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197366704,
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          TIME_THRESHOLD: 5,
        },
        SYS_current_root_id: 'fa08d90d-2dba-4ec2-95ef-223c13ae7180',
        SYS_previous_root_dialog_type: 'IF_NODE',
        SYS_LastName: 'Rathinakumar',
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_previous_dialog_title: 'Fallback',
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_SETTINGS: {
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          end_conversation_message:
            'Your previous session has ended. Please start again',
          disambigutation_nota_option_text: 'None of the above',
          knowledge_ai_bases: null,
          session_termination_reminder_message: 'Your session is about to end',
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          end_conversation_time: 5,
          disambiguation_message: 'What do you want to do?',
          enable_disambiguation: true,
          disambigutation_nota_enabled: true,
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          session_termination_reminder_time: 3,
          updated_at: '2024-02-07T11:35:12',
          disambigutation_nota_handle_node: null,
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          satisfied_feedback: 'Thank you for your valuable feedback',
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          knowledge_ai_source: null,
          created_at: '2022-01-10T08:31:54',
          feedback_message: 'Please provide your feedback',
        },
        metadata: {
          deployment: 'teams',
        },
        SYS_current_root: 'Fallback',
        SYS_User_Latest_Response: 'dwq',
        SYS_Timezone: null,
        SYS_platform: 'teams',
      },
      system: {
        stack: ['transient-node-fa08d90d-2dba-4ec2-95ef-223c13ae7180-0'],
        _id: '65e0484e6b9a600056d28bb8',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [],
        created_at: '2024-02-29T09:03:10.544Z',
        _id: '65e048536b9a600056d28bc0',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'hi',
        intents: [
          {
            _id: '65e048536b9a600056d28bc1',
            intent: 'Greetings',
            confidence: 1,
          },
        ],
        entities: [
          {
            location: [0, 2],
            _id: '65e048536b9a600056d28bc2',
            entity: 'email',
            value: 'manjula',
            confidence: 0,
          },
          {
            location: [0, 2],
            _id: '65e048536b9a600056d28bc3',
            entity: 'greet',
            value: 'greet',
            confidence: 0,
          },
        ],
        faq: [],
        context_variables: {
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_FirstName: 'Arunadevi',
          SYS_current_dialog_title: 'Welcome',
          SYS_TEAMS_CONFIG: {
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197366704,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
          },
          SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_LastName: 'Rathinakumar',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_previous_dialog_title: 'Fallback',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_SETTINGS: {
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            disambigutation_nota_option_text: 'None of the above',
            knowledge_ai_bases: null,
            session_termination_reminder_message:
              'Your session is about to end',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            end_conversation_time: 5,
            disambiguation_message: 'What do you want to do?',
            enable_disambiguation: true,
            disambigutation_nota_enabled: true,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            session_termination_reminder_time: 3,
            updated_at: '2024-02-07T11:35:12',
            disambigutation_nota_handle_node: null,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            satisfied_feedback: 'Thank you for your valuable feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            knowledge_ai_source: null,
            created_at: '2022-01-10T08:31:54',
            feedback_message: 'Please provide your feedback',
          },
          metadata: {
            deployment: 'teams',
          },
          SYS_current_root: 'Welcome',
          SYS_User_Latest_Response: 'hi',
          SYS_Timezone: null,
          SYS_platform: 'teams',
        },
        system: {
          stack: ['33a13cba-bf04-47f2-9d37-b70b3e937374'],
          _id: '65e048536b9a600056d28bc4',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: false,
        },
      },
      {
        output: [
          {
            output: 'text',
            text: {
              text: {
                md_type: 'md_join',
                lhs: {
                  md_type: 'md_normal',
                  value: 'Hi ',
                },
                rhs: {
                  md_type: 'md_join',
                  lhs: {
                    md_type: 'md_normal',
                    value: 'Arunadevi',
                  },
                  rhs: {
                    md_type: 'md_normal',
                    value: ', how can i help you?',
                  },
                },
              },
            },
          },
        ],
        created_at: '2024-02-29T09:03:10.573Z',
        _id: '65e048596b9a600056d28bcb',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'hi',
        intents: [
          {
            _id: '65e048596b9a600056d28bcc',
            intent: 'Greetings',
            confidence: 1,
          },
        ],
        entities: [
          {
            location: [0, 2],
            _id: '65e048596b9a600056d28bcd',
            entity: 'email',
            value: 'manjula',
            confidence: 0,
          },
          {
            location: [0, 2],
            _id: '65e048596b9a600056d28bce',
            entity: 'greet',
            value: 'greet',
            confidence: 0,
          },
        ],
        faq: [],
        context_variables: {
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_FirstName: 'Arunadevi',
          SYS_current_dialog_title: 'Welcome',
          SYS_TEAMS_CONFIG: {
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197366704,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
            TIME_THRESHOLD: 5,
          },
          SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_LastName: 'Rathinakumar',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_previous_dialog_title: 'Fallback',
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_SETTINGS: {
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            disambigutation_nota_option_text: 'None of the above',
            knowledge_ai_bases: null,
            session_termination_reminder_message:
              'Your session is about to end',
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            end_conversation_time: 5,
            disambiguation_message: 'What do you want to do?',
            enable_disambiguation: true,
            disambigutation_nota_enabled: true,
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            session_termination_reminder_time: 3,
            updated_at: '2024-02-07T11:35:12',
            disambigutation_nota_handle_node: null,
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            satisfied_feedback: 'Thank you for your valuable feedback',
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            knowledge_ai_source: null,
            created_at: '2022-01-10T08:31:54',
            feedback_message: 'Please provide your feedback',
          },
          metadata: {
            deployment: 'teams',
          },
          SYS_current_root: 'Welcome',
          SYS_User_Latest_Response: 'hi',
          SYS_Timezone: null,
          SYS_platform: 'teams',
        },
        system: {
          stack: ['transient-node-33a13cba-bf04-47f2-9d37-b70b3e937374-0'],
          _id: '65e048596b9a600056d28bcf',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:03:10.515Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 2,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [
      {
        _id: '65e0484e6b9a600056d28bb9',
        intent: 'Greetings',
        log_id: '65e0484e6b9a600056d28bb3',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        time_stamp: '2024-02-29T09:03:10.515Z',
        __v: 0,
      },
    ],
  },
  {
    _id: '65e048596b9a600056d28bd2',
    req_id: '48e9a100-e04f-4156-989e-24af0a7224bc',
    conversation_id:
      '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
    user_text_id: '62c5a964a3aabca13133f79e',
    masked: false,
    request: {
      output: [],
      created_at: null,
      _id: '65e048596b9a600056d28bd3',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      input: 'agent',
      intents: [
        {
          _id: '65e048596b9a600056d28bd4',
          intent: 'agent',
          confidence: 1,
        },
      ],
      entities: [
        {
          location: [0, 5],
          _id: '65e048596b9a600056d28bd5',
          entity: 'email',
          value: '',
          confidence: 0,
        },
      ],
      faq: [],
      context_variables: {
        SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
        SYS_LastName: 'Rathinakumar',
        SYS_current_root_id: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
        SYS_current_root: 'Welcome',
        SYS_current_root_dialog_type: 'IF_NODE',
        SYS_user_conversation:
          'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        SYS_FirstName: 'Arunadevi',
        SYS_TEAMS_CONFIG: {
          WATSON_LAST_RESPONSE_TIMESTAMP: 1709197390610,
          TIME_THRESHOLD: 5,
          EXIT_MESSAGE: 'Your previous session has ended. Please start again',
        },
        metadata: {
          deployment: 'teams',
        },
        SYS_UserEmail: 'arunadevi.r@workativ.com',
        SYS_previous_root_dialog_type: 'IF_NODE',
        SYS_Username: 'Arunadevi Rathinakumar',
        SYS_current_dialog_title: 'Welcome',
        SYS_SETTINGS: {
          feedback_message: 'Please provide your feedback',
          end_conversation_time: 5,
          else_feedback_message:
            'We are exiting from the conversation now, please start again',
          off_boarding_message:
            'Thank you. Your workplace bot signing off. Please contact your support admin.',
          on_boarding_message:
            'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
          satisfied_feedback: 'Thank you for your valuable feedback',
          session_termination_reminder_message: 'Your session is about to end',
          end_conversation_message:
            'Your previous session has ended. Please start again',
          disambigutation_nota_enabled: true,
          created_at: '2022-01-10T08:31:54',
          not_satisfied_feedback: 'Thank you for your valuable feedback',
          session_termination_reminder_time: 3,
          disambigutation_nota_option_text: 'None of the above',
          updated_at: '2024-02-07T11:35:12',
          disambigutation_nota_handle_node: null,
          enable_disambiguation: true,
          service_not_available:
            'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
          bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          knowledge_ai_source: null,
          disambiguation_message: 'What do you want to do?',
          knowledge_ai_bases: null,
        },
        SYS_previous_dialog_title: 'Fallback',
        SYS_User_Latest_Response: 'hi',
        SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        SYS_platform: 'teams',
        SYS_Timezone: null,
      },
      system: {
        stack: ['transient-node-33a13cba-bf04-47f2-9d37-b70b3e937374-0'],
        _id: '65e048596b9a600056d28bd6',
        platform: 'teams',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        workspace: 'manjulaworkspace',
        branch_exit: true,
      },
    },
    response: [
      {
        output: [
          {
            output: 'hand_over_to_agent',
            hand_over_to_agent: {
              platform: 'liveagent',
              agent_id: 'workativ_liveagent',
            },
          },
        ],
        created_at: '2024-02-29T09:03:17.555Z',
        _id: '65e0485e6b9a600056d28bdd',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        input: 'agent',
        intents: [
          {
            _id: '65e0485e6b9a600056d28bde',
            intent: 'agent',
            confidence: 1,
          },
        ],
        entities: [
          {
            location: [0, 5],
            _id: '65e0485e6b9a600056d28bdf',
            entity: 'email',
            value: '',
            confidence: 0,
          },
        ],
        faq: [],
        context_variables: {
          SYS_COGNIDESK_WORKSPACE: 'manjulaworkspace',
          SYS_LastName: 'Rathinakumar',
          SYS_current_root_id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
          SYS_WELCOME_NODE_ID: '33a13cba-bf04-47f2-9d37-b70b3e937374',
          SYS_current_root: 'Agent Handover',
          SYS_current_root_dialog_type: 'IF_NODE',
          SYS_user_conversation:
            'https://dev-assistant.workativ.ai/dialog-logs/manjulaworkspace/transcript/434981d3-5ec6-449b-ae12-08f6eca28d4c/929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          SYS_FirstName: 'Arunadevi',
          SYS_TEAMS_CONFIG: {
            WATSON_LAST_RESPONSE_TIMESTAMP: 1709197390610,
            TIME_THRESHOLD: 5,
            EXIT_MESSAGE: 'Your previous session has ended. Please start again',
          },
          metadata: {
            deployment: 'teams',
          },
          SYS_UserEmail: 'arunadevi.r@workativ.com',
          SYS_previous_root_dialog_type: 'IF_NODE',
          SYS_Username: 'Arunadevi Rathinakumar',
          SYS_current_dialog_title: 'Agent Handover',
          SYS_SETTINGS: {
            feedback_message: 'Please provide your feedback',
            end_conversation_time: 5,
            else_feedback_message:
              'We are exiting from the conversation now, please start again',
            off_boarding_message:
              'Thank you. Your workplace bot signing off. Please contact your support admin.',
            on_boarding_message:
              'Hello, I’m your workplace bot to help you resolve your workplace support issues and requests.',
            satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_message:
              'Your session is about to end',
            end_conversation_message:
              'Your previous session has ended. Please start again',
            disambigutation_nota_enabled: true,
            created_at: '2022-01-10T08:31:54',
            not_satisfied_feedback: 'Thank you for your valuable feedback',
            session_termination_reminder_time: 3,
            disambigutation_nota_option_text: 'None of the above',
            updated_at: '2024-02-07T11:35:12',
            disambigutation_nota_handle_node: null,
            enable_disambiguation: true,
            service_not_available:
              'Sorry, I’m under maintenance and unable to support now. Please contact your support admin.',
            bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
            knowledge_ai_source: null,
            disambiguation_message: 'What do you want to do?',
            knowledge_ai_bases: null,
          },
          SYS_previous_dialog_title: 'Welcome',
          SYS_User_Latest_Response: 'agent',
          SYS_BOT_ID: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
          SYS_platform: 'teams',
          SYS_Timezone: null,
        },
        system: {
          stack: ['fac9faf6-e4ff-4747-afcf-ca77b3857777'],
          _id: '65e0485e6b9a600056d28be0',
          platform: 'teams',
          conversation_id:
            '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
          workspace: 'manjulaworkspace',
          branch_exit: true,
        },
      },
    ],
    request_at: '2024-02-29T09:03:17.528Z',
    query_id: '82244b53-2fd0-4e69-bbc9-32d864c45dad',
    trained_entity: [],
    __v: 1,
    logConversation: {
      _id: '65e047e56b9a600056d28b2d',
      channel: 'teams',
      feedback: null,
      status: 'escalated',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      conversation_id:
        '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
      user_id: '6413ed830faf98aa465ccbc2',
      start_time: '2024-02-29T09:01:24.972Z',
      end_time: '2024-02-29T09:03:17.555Z',
      __v: 0,
      last_root_node: {
        id: 'fac9faf6-e4ff-4747-afcf-ca77b3857777',
        title: 'Agent Handover',
      },
    },
    users: {
      _id: '6413ed830faf98aa465ccbc2',
      bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
      user_id: 'arunadevi.r@workativ.com',
      __v: 0,
      created_at: '2024-02-29T09:03:21.149Z',
      user_email: 'arunadevi.r@workativ.com',
      user_firstname: 'Arunadevi',
      user_lastname: 'Rathinakumar',
    },
    used_intents: [
      {
        _id: '65e048596b9a600056d28bd7',
        intent: 'agent',
        log_id: '65e048596b9a600056d28bd2',
        bot_id: '434981d3-5ec6-449b-ae12-08f6eca28d4c',
        conversation_id:
          '929b0ec7-755e-4483-907e-9216068de78d_434981d3-5ec6-449b-ae12-08f6eca28d4c_teams',
        time_stamp: '2024-02-29T09:03:17.528Z',
        __v: 0,
      },
    ],
  },
];

[
  {
    response_type: 'text',
    title: {
      md_type: 'md_normal',
      value: 'Can you please provide the feedback for our conversation?',
    },
    options: [
      {
        label: {
          md_type: 'md_normal',
          value: 'Satisfied',
        },
      },
      {
        label: {
          md_type: 'md_normal',
          value: 'Not Satisfied',
        },
      },
    ],
  },
];

export const MockLiveAgent = {
  Online: 1,
  ChattingWithUser: 0,
  Total: 1,
  agent: 'manjula.m@workativ.com',
};

export const CountryListwithISO = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: 'Cote D"Ivoire', code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: 'Korea, Democratic People"S Republic of', code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: 'Lao People"S Democratic Republic', code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russia', code: 'RU' ,otherName:'Russian Federation'},
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE',otherName:"UAE" },
  { name: 'United Kingdom', code: 'GB',otherName:"UK" },
  { name: 'United States', code: 'US',otherName:"USA" },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' },
  { name: 'somaliland', code: 'SOM' },
  { name: 'Kosovo', code: 'XK' },
];

export const mockStausbyLoc=[
    {
        "Resolved": 0,
        "Open": 3,
        "Queue": 5,
        "Closed": 5,
        "TotalCount": 13,
        "Location": null
    },
    {
        "Resolved": 0,
        "Open": 1,
        "Queue": 2,
        "Closed": 0,
        "TotalCount": 3,
        "Location": "India"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "AU"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "Russia"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "Norway"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "China"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 1,
        "Closed": 0,
        "TotalCount": 1,
        "Location": "Greenland"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 9,
        "TotalCount": 9,
        "Location": "Kolkata,India"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "UK"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "Kolkata,UK"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "Kolkata,AU"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "United States"
    },
    {
        "Resolved": 0,
        "Open": 0,
        "Queue": 0,
        "Closed": 1,
        "TotalCount": 1,
        "Location": "Australia"
    }
]